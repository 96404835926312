import React from 'react'
import { Link } from 'gatsby'
import { Layout, SEO } from '../components/common'

const NotFoundPage = () => (
    <React.Fragment>
        <SEO title='404 | Page not found' description='Page not found' dontIndex={true} />
        <Layout>
            <div className="container">
                <article className="content" style={{ textAlign: `center` }}>
                    <h1 className="content-title">Error 404</h1>
                    <section className="content-body">
                        Page not found, <Link to="/">return home</Link> to start over
                    </section>
                </article>
            </div>
        </Layout>
    </React.Fragment>
)

export default NotFoundPage
